@use "variables"

html
  font-family: variables.$font-family-preload
  font-size: .95em

  &.fonts-loaded
    font-family: variables.$font-family
    font-weight: 300
    font-size: 1em

    .card
      h1
        font-weight: 500

    .details
      header
        font-weight: 500

body
  background: radial-gradient(ellipse 100% 80% at 0% 100%, rgba(255 255 232 / 100%), rgba(255 216 196 / 33%), rgba(16 32 64 / 2%) 80%, rgba(16 32 64 / 1%) 100%), linear-gradient(to top, #7d939766, #7d939744, #5e687e33, #474c6d22, #2f345f11, #252b4c11, #12152011), radial-gradient(ellipse 100% 80% at 0% 100%, rgba(255 255 128 / 66%), rgba(255 128 32 / 33%), rgba(32 64 128 / 12%) 66%), linear-gradient(to top, #7d9397, #7d9397, #5e687e, #474c6d, #2f345f, #252b4c, #121520)
  background-blend-mode: difference
  margin: 0
  padding: 0
  color: white
  text-align: center

  > .content
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
  
a
  border-bottom: 1px solid
  color: variables.$link-color
  padding-bottom: -1px
  text-decoration: none
  text-shadow: 1px 1px 2px rgba(0 0 0 / 33%)

  &:visited
    color: variables.$link-visited-color

  &:focus
    background-color: #000
    color: #fff


input,
textarea
  &:focus
    border: 1px solid variables.$link-color

.error
  color: red

.details--message-feedback
  margin: 0.5rem 0

button
  &:focus
    background-color: variables.$link-color
    color: variables.$background-color

.card
  padding: 2rem
  text-shadow: 2px 2px 4px  rgba(0 0 0 / 66%)
  h1
    font-size: 1.6rem
    margin: .25rem 0

  p
    font-size: 1.6rem
    margin: .25rem 0

$bit-size: .7

.bits
  display: block
  font-size: 1rem * $bit-size
  height: (5 * $bit-size) * 1rem - .1rem
  line-height: .8
  margin: 0
  overflow: hidden
  padding-top: .2rem
  width: 100%
    
.bit
  display: inline-block
  height: 1rem * $bit-size
  margin: 0
  width: .7rem * $bit-size

  &::before
    content: '0'
    text-shadow: 1px 1px 2px #000

  &.val-1
    &::before
      content: '1'

.details
  font-size: 1.1rem
  line-height: 1.66
  margin: 2rem

  header
    margin-bottom: 1rem
    text-shadow: 2px 2px 2px  rgba(0 0 0 / 33%)

.details--list
  list-style-type: none
  margin: 0
  padding: 0

.details--icon
  height: 1.2rem
  margin-right: .5rem
  vertical-align: text-bottom
  width: 1.2rem

#details--contact-container,
#details--contact-response-container
  display: none
  margin-bottom: 1rem
  margin-left: 1.7rem

  .container
    display: flex
    flex-direction: column

.textarea--large
  height: 6rem
  max-width: 24rem
  width: 100%
  margin-left: auto
  margin-right: auto

@media (min-width: 576px)
  .card
    h1
      font-size: 2.3rem
      margin: .25rem 0

    p
      font-size: 1.6rem
      margin: .25rem 0

@media(min-height: 48rem)
  body
    min-height: 100vh
